<template>
  <div>
    <section class="u-spacing-p-">
      <component
        :is="customActionPanelCustomState.component"
        v-if="customActionPanelCustomState"
        :key="addTargetKey"
        class="add-keyword-campaign-creation"
        v-bind="customActionPanelCustomState.props"
        v-on="customActionPanelCustomState.events"
      >
        <!-- <div
          v-if="targetMessages && !targetsLength"
          slot="right-table-footer"
          class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
        >
          <rb-icon
            class="u-color-orange-base rb-icon--small u-display-block"
            icon="info-circle-fill"
          /> {{ targetMessages }}
        </div> -->
        <div slot="leftTableHeader">
          <GenericTabsComponent
            :tabs-list="tabList"
            :default-tab-index="defaultTabIndex"
            @handleTabsClick="handleTabsClick"
          />
        </div>
      </component>
    </section>
  </div>
</template>

<script>
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';
export default {
  components: {
    CustomActionPanel,
    GenericTabsComponent
  },
  props: {
    campaignType: {
      type: String,
      default: 'sp'
    },
    widgetConfigs: {
      type: Object,
      default: () => {
        return {};
      }
    },
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },
    defaultTab: {
      type: String,
      default: 'Categories'
    }
  },
  data: () => ({
    addTargetKey: 0,
    defaultTabIndex: 0,
    tabList: ['Categories', 'Products'],
    customActionPanelCustomState: null,
    customActionPanelNegativeTargetingCustomState: null,
    categoriesActionState: null
  }),
  computed: {},
  watch: {},
  created() {
    this.handleTabsClick(this.defaultTab);
  },
  methods: {
    handleTabsClick(value) {
      if (value === 'Categories') {
        this.customActionPanelCustomState = this.widgetConfigs.categories(
          this.context,
          this.campaignType
        );
        this.defaultTabIndex = 0;
      } else {
        this.customActionPanelCustomState = this.widgetConfigs.products(
          this.context
        );
        this.defaultTabIndex = 1;
      }
      if (this.addTargetKey === 0) {
        // clearing local state if any.
        this.customActionPanelCustomState?.saveAddedProductAndCategoryState?.(
          []
        );
      }
      this.addTargetKey++;
    }
  }
};
</script>

<style lang="css">
.add-keyword-campaign-creation .table-container-entity-asin-tagging {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
.add-keyword-campaign-creation .custom-entity .split-table-section {
  padding-right: 2.4rem;
  padding-left: 2.4rem;
}
.add-keyword-campaign-creation .custom-entity .split-table-section > h2 {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}
</style>

<style scoped>
.edit_suggested_campaign_keyword_targeting {
  bottom: -20px;
}
</style>
